import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

async function getRoot(Id){
  const response =  await axios.get(`${API_URL}/intranet/immeuble/${Id}/folders`, {headers: authHeader()});
  return response.data;
}

async function getFolder(Id){
  const response =  await axios.get(`${API_URL}/intranet/folder/${Id}`, {headers: authHeader()});
  return response.data;
}

async function getFile(Id){
  const response =  await axios.get(`${API_URL}/intranet/file/${Id}`, {headers: authHeader()});
  return response.data;
}

async function getFileRight(Id){
  const response =  await axios.get(`${API_URL}/intranet/file/${Id}/right`, {headers: authHeader()});
  return response.data;
}

async function addFolderRoot(Id, FolderName){
  const formData = new FormData();
  formData.append("name", FolderName)
  const response = await axios.post(`${API_URL}/intranet/immeuble/${Id}/folders/add`, formData, {headers: authHeader()});
  return response.data;
}

async function addFolder(Id, FolderName){
  const formData = new FormData();
  formData.append("name", FolderName);
  const response = await axios.post(`${API_URL}/intranet/folder/${Id}/folders/add`, formData, {headers: authHeader()});
  return response.data;
}

async function addFiles(Id, Files){
  const formData = new FormData();
  Files.files.forEach((file) => {
    formData.append(`files[]`, file);
  });
  const response = await axios.post(`${API_URL}/intranet/folder/${Id}/files/add`, formData, {headers: authHeader()});
  return response.data;
}

async function updateFolder(IdParent, Id, FolderName){
  const formData = new FormData();
  formData.append("name", FolderName);
  const response = await axios.post(`${API_URL}/intranet/folder/${IdParent}/update/folder/${Id}`, formData, {headers: authHeader()});
  return response.data;
}

async function updateFile(IdParent, Id, FileName){
  const formData = new FormData();
  formData.append("name", FileName);
  const response = await axios.post(`${API_URL}/intranet/folder/${IdParent}/update/file/${Id}`, formData, {headers: authHeader()});
  return response.data;
}

async function delFolder(Id){
  await axios.delete(`${API_URL}/intranet/folder/delete/${Id}`, {headers: authHeader()});
}

async function delFile(Id){
  await axios.delete(`${API_URL}/intranet/file/delete/${Id}`, {headers: authHeader()});
}

async function moveFile(Id, FolderId){
  const formData = new FormData();
  formData.append("folder", FolderId);
  const response = await axios.post(`${API_URL}/intranet/file/move/${Id}`, formData, {headers: authHeader()});
  return response.data;
}

async function getUsers() {
  const response = await axios.get(`${API_URL}/intranet/users`, {headers: authHeader()});
  return response.data;
}

async function getUserRights(UserId, FolderId) {
  const response = await axios.get(`${API_URL}/intranet/users/${UserId}/folder/${FolderId}`, {headers: authHeader()});
  return response.data;
}

async function changeRight(UserId, Type, Id, Droit){
  const formData = new FormData();
  if (Type == 'd') {
    formData.append("folder", Id);
  }
  else {
    formData.append("file", Id);
  }
  formData.append("right",Droit);
  const response = await axios.post(`${API_URL}/intranet/users/${UserId}/rights/change`, formData, {headers: authHeader()});
  return response.data;
}

async function getImmInfos(Id){
  const response =  await axios.get(`${API_URL}/intranet/immeuble/${Id}/infos`, {headers: authHeader()});
  return response.data;
}

async function getPath(Id){
  const response =  await axios.get(`${API_URL}/intranet/dossier/${Id}/chemin`, {headers: authHeader()});
  return response.data;
}

async function getFolderContent(Id) {
  const response =  await axios.get(`${API_URL}/intranet/dossier/${Id}/contenu`, {headers: authHeader()});
  return response.data;
}

export default {
    getRoot,
    getFolder,
    getFile,
    getFileRight,
    addFolderRoot,
    addFolder,
    addFiles,
    updateFolder,
    updateFile,
    delFolder,
    delFile,
    moveFile,
    getUsers,
    getUserRights,
    changeRight,
    getImmInfos,
    getPath,
    getFolderContent
};
