<template>
    <teleport to="body">
        <div v-if="show" class="modal-backdrop">
            <div class="modal-content">
                <div>
                    <button type="button" class="btn-close btn-close-black" aria-label="Close" @click="close"></button>
                    <h4 class="text-white text-center"> Mes droits </h4>
                </div>
                <div class = "modal-body custom-scroll"> 
                    <div class="card p-3 bg-light text-black mb-2">
                        <h6 class="text-center"> Dossier courant </h6>
                        <table class="bigTable">
                            <tr>
                                <td class="col-uni text-black"> {{ data[0].name }}</td>
                                <td class="col-uni"> {{ data[1].droit }}</td>
                            </tr>
                        </table>
                        <h6 v-if="data[2].length>0" class="text-center"> Dossiers </h6>
                        <table class="bigTable">
                            <tr v-for="folder in data[2]" :key="folder.id">
                                <td class="col-uni"> {{ folder.name }} </td>
                                <td class="col-uni"> {{ findFolderRight(folder.id) }} </td>
                            </tr>
                        </table>
                        <h6 class="text-center"> Fichiers </h6>
                        <table class="bigTable">
                            <tr v-for="file in data[4]" :key="file.id">
                                <td class="col-uni"> {{ file.name }} </td>
                                <td class="col-uni"> {{ findFileRight(file.id) }} </td>
                            </tr>
                        </table>
                    </div>
                    <h4 class=" mt-2 text-center text-white"> Légende </h4>
                    <div class="card p-3 bg-light text-black">
                        <h6 class="text-center"> Dossiers </h6>
                        <tr> O : accès au dossier </tr>
                        <tr> U : O + création de dossiers et import de fichiers </tr>
                        <tr> A : U + supprimer et renommer le dossier + modifier les droits </tr>
                        <h6 class="text-center"> Fichiers </h6>
                        <tr> L : lire le fichier </tr>
                        <tr> C : L + commenter le fichier </tr>
                        <tr> E : C + modifier le fichier </tr>
                        <tr> A : E + supprimer et déplacer le fichier + modifier les droits</tr>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>

export default {
    name:"MyRights",
    props: {
        show: {
            type: Boolean,
            required: true
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['close'],
    methods: {
        findFolderRight(folderId) {
            const folderRights = this.data[3]
            const index = folderRights.findIndex(item => item.dossier === folderId);
            const droit = folderRights[index].droit;
            return droit;
        },
        findFileRight(fileId) {
            const fileRights = this.data[5]
            const index = fileRights.findIndex(item => item.fichier === fileId);
            const droit = fileRights[index].droit;
            return droit;
        },
        close () {
            this.$emit('close');
        }
    },
}

</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.425);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background: rgb(26, 36, 54);
  position: relative;
  max-height: 90vh;
  overflow: hidden;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.custom-scroll {
  overflow-y: auto; /* Barre de défilement verticale */
  padding: 20px;
  max-height: 80vh; /* Hauteur maximale de la zone de contenu, ajustable */
}
.bigTable {
    table-layout: fixed;
}

.col-uni {
    width: 33%;
    text-align: center;
}

</style>