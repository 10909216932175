<template>
    <Transition name="slide-up" mode="out-in">
  <div class="card  p-3" v-if="item.ticket">

    <h3 class="h4">{{ item.ticket_reception?.title }}</h3>
    <div>
        <button class="btn btn-xs btn-danger" @click="goImm(item.ticket.immeuble.id)">{{item.ticket?.immeuble?.nom}}</button>
        <div class="btn btn-xs bg-default text-white">{{ new Date(item.created_at).toLocaleDateString("fr") }}</div>
        <button class="btn btn-xs bg-yellow" @click="goPerso(item.ticket_reception.declarant.id)">{{item.ticket_reception?.declarant?.nom}}</button>

        <div v-for=" l in item.ticket?.attributes?.lots" class="btn btn-xs bg-info text-white"
            :key="l.id"
            >
            {{l.title}}
        </div>


    </div>

        {{ item.ticket_reception?.description }}



  </div>
</Transition>
</template>
<script>

export default {
  components: {
  },
  props: ["id"],
  data() {
    return {
      item: {
        ticket_reception:{
            title: '',
            description: ''
        }
      },
      contexte: [],
      modals: {
        corrigerContexte: {
          show: false,
        },
      },
    };
  },
  watch: {
    id: {
      async handler() {
        this.get();
      },
    },
  },
  mounted() {
    //this.item = this.$route.params.item;
    this.get();
  },
  methods: {
    goImm(id) {
      this.$router.push({
        name: "Immeuble",
        params: {
          id: id,
        },
      });
    },
    goPerso(id) {
      this.$router.push({
        name: "Perso",
        params: {
          id: id,
        },
      });
    },
    goEvent(id) {
      this.$router.push({
        name: "Immeuble",
        params: {
          id: id,
        },
      });
    },
    async get() {
      try {
        console.log('QUERY', this.id)

        const id = this.id;
        console.log("QUERY", id, this.$route.query);
        this.mainid = id;
        if (!id) {
          return;
        }
        await this.$store.dispatch("call/getcall", id);
        var b = this.$store.getters["call/onecall"];
        if(b.ticket?.attributes && typeof b.ticket.attributes === 'string'){
            b.ticket.attributes = JSON.parse(b.ticket.attributes);
        }
        console.log("GET", b);
        this.item = b;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Problème de récupération de l'e-mail!`,
        });
      }
    },
  },
};
</script>
