<template>
  <div class="py-1 container-fluid">
    <div class="header bg-success bg-gradient-danger pb-1 border-radius-xl">
      <div class="py-1 container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-3 text-left">
            <button class="btn btn-white mt-2 btn-xs" @click="router.go(-1)">
              <span class="btn-inner--icon"><i class="fas fa-arrow-left"></i></span> &nbsp; Retour
            </button>
          </div>
          <div class="col-lg-6 text-center">
            <h6 class="h6 text-white d-inline-block mb-0"> {{ immInfos.EXTAPI_IMM_ID }} &nbsp;</h6>
            <h6 class="h4 text-white d-inline-block mb-0"> {{ immInfos.nom }}</h6>
          </div>
          <div class="col-lg-3 text-end">
            <button class="btn btn-white mt-2 btn-xs" @click="openImmeuble()">
              <i class="fas fa-info"></i> &nbsp;Immeuble
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-1 container-fluid">
    <table>
      <tr class="text-lg">
        <td style="padding: 10px"> 
          <h6 style="cursor: pointer;" @click="openIntranet"> 
            <i class="fas fa-arrow-right"></i> Immeubles  
          </h6>
        </td>
        <td style="padding: 10px"> 
          <h6 style="cursor: pointer;" @click="openImm"> 
            <i class="fas fa-arrow-right"></i> {{ immInfos.EXTAPI_IMM_ID }}  
          </h6>
        </td>
        <td v-for="folder in chemin" :key="folder.id" style="padding: 10px"> 
          <h6 style="cursor: pointer;" @click="openFolder(folder.id)" @dragover.prevent @drop="onDrop(folder.id)"> 
            <i class="fas fa-arrow-right"></i> {{ folder.name }}  
          </h6></td>
      </tr>
    </table>
  </div>
  <div class="py-1 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-9 text-left">
        <div v-if="vueListe">
          <h4 v-if="folderDataTable.length > 0 || fileDataTable.length > 0" class="text-black d-inline-block mb-0"> Documents</h4>
          <div class="card">
            <div class="px-0 pb-0 pt-1 card-body">
              <div id="infinite-list" class="table-responsive" style="height:calc(100vh - 200px)" @scroll="handleScroll">
                <table class="table table-responsive">
                  <tbody class="text-lg">
                    <tr>
                      <td > Nom </td>
                      <td > Dernière modification </td>
                      <td > Taille </td>
                      <td style="text-align: center"> Renommer </td>
                      <td style="text-align: center"> Supprimer </td>
                    </tr>
                    <tr v-for="folder in folderDataTable" :key="folder.id">
                      <td style="cursor: pointer;" @click="openFolder(folder.id)" @dragover.prevent @drop="onDrop(folder.id)">
                        <i class="fas fa-solid fa-folder"></i> {{ folder.name }}
                      </td>
                      <td> {{ dateModif(folder.updated_at) }} </td>
                      <td> {{ "-" }} </td>
                      <td v-if="folderRight(folder.id, ['S','A'])" style="cursor: pointer;text-align: center" @click="openUpdateFolder(folder.id)">
                        <i class="fas fa-solid fa-pen"></i>
                      </td>
                      <FolderUpdate :show="showUpdateFolder" @close="showUpdateFolder = false" @submit="updateFolder"></FolderUpdate>
                      <td v-if="folderRight(folder.id, ['S','A'])" style="cursor: pointer;text-align: center" @click="deleteFolder(folder.id)">
                        <i class="fas fa-solid fa-trash"></i>
                      </td>
                      <DelConfirm :show="showDelConfirm" :name="folder.name" @close="showDelConfirm = false" @submit="delFolderContent"> </DelConfirm>
                    </tr>
                    <tr v-for="file in fileDataTable" :key="file.id">
                      <td style="cursor: pointer;" draggable="true" @click="getURL(file.id)" @dragstart="onDragStart(file.id)">
                        <i v-if="extension(file.name)=='pdf'" class="fas fa-regular fa-file-pdf" style="color: #b30000;"></i> 
                        <i v-if="extension(file.name)=='w'" class="fas fa-regular fa-file-word" style="color: #082d6d;"></i> 
                        <i v-if="extension(file.name)=='e'" class="fas fa-regular fa-file-excel" style="color: #006614;"></i> 
                        <i v-if="extension(file.name)=='p'" class="fas fa-regular fa-file-powerpoint" style="color: #f58300;"></i> 
                        <i v-if="extension(file.name)=='i'" class="fas fa-regular fa-image" style="color: #cd3c89;"></i> 
                        <i v-if="extension(file.name)=='txt'" class="fas fa-regular fa-file-lines" style="color: #9d56bd;"></i> 
                        <i v-if="extension(file.name)=='n'" class="fas fa-solid fa-file"></i> {{ file.name }}
                      </td>
                      <td> {{ dateModif(file.updated_at) }} </td>
                      <td> {{ taille(file.taille) }} </td>
                      <td v-if="fileRight(file.id, ['S','A','E'])" style="cursor: pointer;text-align: center" @click="openUpdateFile(file.id)">
                        <i class="fas fa-solid fa-pen"></i>
                      </td>
                      <FileUpdate :show="showUpdateFile" @close="showUpdateFile = false" @submit="updateFile"></FileUpdate>
                      <td v-if="fileRight(file.id, ['S','A'])" style="cursor: pointer;text-align: center" @click="deleteFile(file.id)">
                        <i class="fas fa-solid fa-trash"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 v-if="folderDataTable.length > 0" class="text-black d-inline-block mb-0"> Dossiers</h4>
          <div class="row" >
            <div v-for="folder in folderDataTable" :key="folder.id" class="col-lg-3">
              <div class="card p-3 mb-2 bg-light">
                <div class="row" >                
                  <div class="col-lg-10">
                    <div class="card p-3" @click="openFolder(folder.id)" @dragover.prevent @drop="onDrop(folder.id)">
                      <h6 style="flex:2;width: 100%;cursor: pointer;">
                        <i class="fas fa-solid fa-folder"></i> {{ folder.name }}
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <button v-if="folderRight(folder.id, ['S','A'])" @click="deleteFolder(folder.id)">
                      <i class="fas fa-solid fa-trash"></i>
                    </button>
                    <DelConfirm :show="showDelConfirm" :name="folder.name" @close="showDelConfirm = false" @submit="delFolderContent"> </DelConfirm>
                    <button v-if="folderRight(folder.id, ['S','A'])" @click="openUpdateFolder(folder.id)">
                      <i class="fas fa-solid fa-pen"></i>
                    </button>
                    <FolderUpdate :show="showUpdateFolder" @close="showUpdateFolder = false" @submit="updateFolder"></FolderUpdate>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 v-if="fileDataTable.length > 0" class="text-black d-inline-block mb-0"> Fichiers</h4>
          <div class="row" >
            <div v-for="file in fileDataTable" :key="file.id" class="col-lg-3">
              <div class="card p-3 mb-2 bg-light">
                <div class="row" >                
                  <div class="col-lg-10">
                    <div draggable="true" class="card p-3" @click="getURL(file.id)" @dragstart="onDragStart(file.id)">
                      <h6 style="flex:2;width: 100%;cursor: pointer;">
                        <i v-if="extension(file.name)=='pdf'" class="fas fa-regular fa-file-pdf" style="color: #b30000;"></i> 
                        <i v-if="extension(file.name)=='w'" class="fas fa-regular fa-file-word" style="color: #082d6d;"></i> 
                        <i v-if="extension(file.name)=='e'" class="fas fa-regular fa-file-excel" style="color: #006614;"></i> 
                        <i v-if="extension(file.name)=='p'" class="fas fa-regular fa-file-powerpoint" style="color: #f58300;"></i> 
                        <i v-if="extension(file.name)=='i'" class="fas fa-regular fa-image" style="color: #cd3c89;"></i> 
                        <i v-if="extension(file.name)=='txt'" class="fas fa-regular fa-file-lines" style="color: #9d56bd;"></i> 
                        <i v-if="extension(file.name)=='n'" class="fas fa-solid fa-file"></i> {{ file.name }}
                      </h6>
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <button v-if="fileRight(file.id, ['S','A'])" @click="deleteFile(file.id)">
                      <i class="fas fa-solid fa-trash"></i>
                    </button>
                    <button v-if="fileRight(file.id, ['S','A','E'])" @click="openUpdateFile(file.id)">
                      <i class="fas fa-solid fa-pen"></i>
                    </button>
                    <FileUpdate :show="showUpdateFile" @close="showUpdateFile = false" @submit="updateFile"></FileUpdate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 text-left">
        <h4 class="text-black d-inline-block mb-0"> Mes options</h4>
        <div v-if="vueListe" class="card p-3">
          <div v-for="right in thisFolderRightTable" :key="right.id" class="row">
            <button class="btn btn-white bg-light mb-2" @click="vueListe = false">
              <i class="fas fa-solid fa-grip-horizontal"></i>&nbsp;Vue grille
            </button>
            <button v-if="folderRight(right.dossier, ['S','A','U'])" class="btn btn-white bg-light mb-2" @click="showAddFolder = true">
              <i class="fas fa-plus"></i>&nbsp;Ajouter un dossier
            </button>
            <AddFolder 
              :show="showAddFolder" @close="showAddFolder = false" @submit="createFolder">
            </AddFolder>
            <button v-if="folderRight(right.dossier, ['S','A','U'])" class="btn bg-light btn-white" @click="showAddFile = true">
              <i class="fas fa-plus"></i>&nbsp;Ajouter un fichier
            </button>
            <AddFile
              v-model="selectedFiles" :show="showAddFile" @close="showAddFile = false" @submit="addFiles">
            </AddFile>
            <button v-if="cheminLoaded" class="btn bg-light btn-white" @click="showMyRights = true">
              <i class="fas fa-solid fa-eye"></i>&nbsp;Consulter mes droits
            </button>
            <MyRights
              :show="showMyRights" 
              @close="showMyRights = false" 
              :data="dataMyRights()">
            </MyRights>
            <button v-if="usersLoaded & folderRight(right.dossier, ['S','A'])" class="btn bg-light btn-white" @click="showRights = true">
              <i class="fas fa-solid fa-lock"></i>&nbsp;Gérer les droits
            </button>
            <Rights
              :show="showRights" 
              @close="showRights = false"
              :thisFolder="right.dossier"
              :myId="right.user"
              :myRight="right.droit"
              :data="dataRights()">
            </Rights>
          </div>
        </div>
        <div v-else class="card p-3 bg-light">
          <div v-for="right in thisFolderRightTable" :key="right.id" class="row">
            <button class="btn btn-white mb-2" @click="vueListe = true">
              <i class="fas fa-solid fa-list"></i>&nbsp;Vue liste
            </button>
            <button v-if="folderRight(right.dossier, ['S','A','U'])" class="btn btn-white mb-2" @click="showAddFolder = true">
              <i class="fas fa-plus"></i>&nbsp;Ajouter un dossier
            </button>
            <AddFolder 
              :show="showAddFolder" @close="showAddFolder = false" @submit="createFolder">
            </AddFolder>
            <button v-if="folderRight(right.dossier, ['S','A','U'])" class="btn btn-white" @click="showAddFile = true">
              <i class="fas fa-plus"></i>&nbsp;Ajouter un fichier
            </button>
            <AddFile
              v-model="selectedFiles" :show="showAddFile" @close="showAddFile = false" @submit="addFiles">
            </AddFile>
            <button v-if="cheminLoaded" class="btn btn-white" @click="showMyRights = true">
              <i class="fas fa-solid fa-eye"></i>&nbsp;Consulter mes droits
            </button>
            <MyRights
              :show="showMyRights" 
              @close="showMyRights = false" 
              :data="dataMyRights()">
            </MyRights>
            <button v-if="usersLoaded & folderRight(right.dossier, ['S','A'])" class="btn btn-white" @click="showRights = true">
              <i class="fas fa-solid fa-lock"></i>&nbsp;Gérer les droits
            </button>
            <Rights
              :show="showRights" 
              @close="showRights = false"
              :thisFolder="right.dossier"
              :myId="right.user"
              :myRight="right.droit"
              :data="dataRights()">
            </Rights>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import setNavPills from "@/assets/js/nav-pills.js";
import AddFolder from './AddFolder.vue';
import FolderUpdate from './FolderUpdate.vue';
import FileUpdate from './FileUpdate.vue';
import AddFile from './AddFile.vue';
import MyRights from './MyRights.vue';
import Rights from './Rights.vue';
import DelConfirm from './DelConfirm.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();
const folderData = ref([]);
const fileData = ref([]);
const folderDataRight = ref([]);
const fileDataRight = ref([]);
const thisFolderRight = ref([]);
const imm = ref([]);
const cheminData = ref([]);
const usersData = ref([]);
const showAddFolder = ref(false);
const showUpdateFolder = ref(false);
const showUpdateFile = ref(false);
const showAddFile = ref(false);
const showMyRights = ref(false);
const showRights = ref(false);
const showDelConfirm = ref(false);
const idFolderToUpdate = ref(0);
const idFileToUpdate = ref(0);
const idFolderToDelete = ref(0);
const selectedFiles = ref([]);
const draggedFile = ref(0);
const vueListe = ref(true);
const loaded = ref(false);
const cheminLoaded = ref(false);
const usersLoaded = ref(false);

const dataMyRights = () => {
  if (loaded.value==true && cheminLoaded.value==true) {
    const data = [];
    data.push(chemin.value[chemin.value.length -1])
    data.push(thisFolderRight.value[0])
    data.push(folderDataTable.value)
    data.push(folderDataRightTable.value)
    data.push(fileDataTable.value)
    data.push(fileDataRightTable.value)
    return data
  }
  else {
    return []
  }
}

const dataRights = () => {
  const ready = ref(false)
  const users = ref([])
  const liste = ref([])
  try {
    liste.value = JSON.parse(localStorage.getItem('collabs') || '[]');
  }
  finally {
    users.value = (liste.value.map(element => ({email: element.user.email, id: element.user.id })))
    ready.value=true
  }
  if (usersLoaded.value==true && ready.value==true) {
    const data = [];
    data.push(folderDataTable.value)
    data.push(folderDataRightTable.value)
    data.push(fileDataTable.value)
    data.push(fileDataRightTable.value)
    data.push(usersTable.value);
    data.push(users.value)
    const changeFolders = Array(data[0].length).fill(false);
    const changeFiles = Array(data[2].length).fill(false);
    data.push(changeFolders)
    data.push(changeFiles)
    return data
  }
  else {
    return []
  }
}

const fileRight = (fileId, Rtable) => {
  if (loaded.value==true) {
    const index = fileDataRightTable.value.findIndex(item => item.fichier === fileId);
    if (index>=0) {
      const droit = fileDataRightTable.value[index].droit;
      const response = Rtable.some(R => R === droit);
      return response;
    }
    else {
      return true;
    }
  }
}

const folderRight = (folderId, Rtable) => {
  if (loaded.value==true) {
    const index = folderDataRightTable.value.findIndex(item => item.dossier === folderId);
    if (index>=0) {
      const droit = folderDataRightTable.value[index].droit;
      const response = Rtable.some(R => R === droit);
      return response;
    }
    else {
      return true;
    }
  }
  
}

const openUpdateFolder = (id) => {
  showUpdateFolder.value = true
  idFolderToUpdate.value = id;
}

const openUpdateFile = (id) => {
  showUpdateFile.value = true
  idFileToUpdate.value = id;
}

const getURL = async(fileId) => {
  await store.dispatch("intranet/getFile", fileId);
  if (store.getters['intranet/fileURL']) {
    const url = store.getters['intranet/fileURL'];
    window.open(url, '_blank');
  }
}

const addFiles = async (files) => {
  const folderId = route.params.folderId;
  await store.dispatch("intranet/addFiles", {itemId : folderId,files});
  if (store.getters["intranet/files"]) {
    const newFiles = await store.getters["intranet/files"].files;
    const newFilesRights = await store.getters["intranet/files"].rights;
    newFiles.forEach(file => {
      fileDataTable.value.push(file);
    });
    newFilesRights.forEach(droit => {
      fileDataRightTable.value.push(droit)
    });
    selectedFiles.value=[];
  }
}

const deleteFolder = async (id) => {
  await store.dispatch("intranet/getFolderContent", id);
  const response = store.getters["intranet/contenu"];
  if (response == true) {
    showDelConfirm.value = true;
    idFolderToDelete.value = id;
  } else {
    await store.dispatch("intranet/delFolder", id);
    const index = folderDataTable.value.findIndex(item => item.id === id);
    if (index !== -1) {
      folderDataTable.value.splice(index, 1);
    } else {
      alert('Erreur lors de la suppression du dossier');
    }
  }
  
};

const delFolderContent = async (supprimer) => {
  showDelConfirm.value = false;
  const id = idFolderToDelete.value;
  if (supprimer) {
    await store.dispatch("intranet/delFolder", id);
    const index = folderDataTable.value.findIndex(item => item.id === id);
    if (index !== -1) {
      folderDataTable.value.splice(index, 1);
    } else {
      alert('Erreur lors de la suppression du dossier et de ce qu\'il contient');
    }
  }
}

const deleteFile = async (id) => {
  await store.dispatch("intranet/delFile", id);
  const index = fileDataTable.value.findIndex(item => item.id === id);
    if (index !== -1) {
      fileDataTable.value.splice(index, 1);
    } else {
      alert('Erreur lors de la suppression du fichier');
    }
};

const updateFolder = async (text) => {
  showUpdateFolder.value = false;
  const folderName = text;
  const parentId = route.params.folderId;
  const id = idFolderToUpdate.value;
  await store.dispatch("intranet/updateFolder", {itemId : parentId,id,folderName});
  if (store.getters["intranet/oneFolder"]) {
    const newFolder = await store.getters["intranet/oneFolder"];
    const newName = newFolder.name;
    const index = folderDataTable.value.findIndex(item => item.id === id);
    if (index !== -1) {
      folderDataTable.value[index].name=newName;
    } else {
      alert('Erreur lors du renommage du dossier');
    }
  }
  else {
    alert('Un dossier portant ce nom existe déjà');
  }
};

const updateFile = async (text) => {
  showUpdateFile.value = false;
  const parentId = route.params.folderId;
  const id = idFileToUpdate.value;
  const index = fileDataTable.value.findIndex(item => item.id === id);
  if (index !== -1) {
    const nameWithExt = fileDataTable.value[index].name;
    const parts = nameWithExt.split('.');
    const ext = parts.length > 1 ? '.'+parts.pop() : '';
    const fileName = text + ext;
    await store.dispatch("intranet/updateFile", {itemId : parentId,id,fileName});
    if (store.getters["intranet/oneFile"]) {
      const newFile = await store.getters["intranet/oneFile"];
      const newName = newFile.name;
      fileDataTable.value[index].name=newName;
    } else {
      alert('Erreur lors du renommage du fichier');
    }
  }
  else {
    alert('Un dossier portant ce nom existe déjà');
  }
};

// Fonction appelée au début du glisser
const onDragStart = (id) => {
  draggedFile.value = id;
}

// Fonction appelée lorsque le fichier est déposé dans un dossier
const onDrop = async (folderId) => {
  if (draggedFile.value) {
    const id = draggedFile.value
    await store.dispatch("intranet/moveFile", {id, folderId});
    if (store.getters["intranet/oneFile"]) {
      if (store.getters["intranet/oneFile"].file) {
        const index = fileDataTable.value.findIndex(item => item.id === id);
        if (index !== -1) {
          fileDataTable.value.splice(index, 1);
        }
      }
      else {
        const reason = store.getters["intranet/oneFile"].reason;
        if (reason == 'd') {
          alert('Vous n\'avez pas les droits nécessaires pour déplacer des fichiers dans ce dossier');
        }
        else {
          alert('Vous n\'avez pas les droits nécessaires pour déplacer ce fichier');
        }
      }
    }
    draggedFile.value = 0;
  }
}

// Méthode pour gérer la soumission du texte depuis le modal
const createFolder = async (text) => {
  showAddFolder.value = false;
  const folderName = text;
  await store.dispatch("intranet/addFolder", {itemId : route.params.folderId,folderName});
  if (store.getters["intranet/oneFolder"]) {
    const newFolder = await store.getters["intranet/oneFolder"].dossier;
    const newFolderRight = await store.getters["intranet/oneFolder"].droit;
    folderDataTable.value.push(newFolder);
    folderDataRightTable.value.push(newFolderRight);
  }
  else {
    alert('Un dossier portant ce nom existe déjà');
  }
};

const taille = (n) => {
  if (n>1024) {
    n=n/1024
    if (n>1024) {
      n=n/1024
      n=Math.floor(n * 10) / 10;
      return (n + " Mo")
    }
    else {
      n=Math.floor(n * 10) / 10;
      return (n + " Ko")
    }
  }
  else {
      n=Math.floor(n * 10) / 10;
      return (n + " o")
    }
}

function dateModif(timestamp) {
    const date = new Date(timestamp);

    // Extraire les composants de la date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Formater la date au format "année-jour-mois heure-minute"
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

function extension(name) {
  const extension = name.includes('.') ? name.split('.').pop() : '';
  if (extension === 'pdf') {
    return 'pdf';
  }
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
    return 'i';
  }
  if (extension === 'doc' || extension === 'docx' || extension === 'odt') {
    return 'w';
  }
  if (extension === 'xls' || extension === 'xlsx' || extension === 'ods') {
    return 'e';
  }
  if (extension === 'ppt' || extension === 'pptx' || extension === 'odp') {
    return 'p';
  }
  if (extension === 'txt') {
    return 'txt'
  }
  return 'n'
}

const fetchData = async () => {
  const folderId = route.params.folderId
  await store.dispatch("intranet/getFolder", folderId)
  try {
    folderData.value = store.getters['intranet/folders'].dossiers
    fileData.value = store.getters['intranet/folders'].fichiers
    folderDataRight.value = store.getters['intranet/folders'].dossiersDroit
    fileDataRight.value = store.getters['intranet/folders'].fichiersDroit
  }
  finally {
    loaded.value=true;
    const table =[]
    table.push(store.getters['intranet/folders'].dossiersDroit[0])
    thisFolderRight.value = table;
  }
  await store.dispatch("intranet/getPath", folderId);
  try {
    cheminData.value = store.getters['intranet/path'].reverse();
  }
  finally {
    cheminLoaded.value=true;
  }
  await store.dispatch("intranet/getUsers");
  try {
    usersData.value = store.getters['intranet/users'];
  }
  finally {
    usersLoaded.value=true;
  }
};

const fetchDataImmeuble = async () => {
  const id = route.params.id;
  await store.dispatch("intranet/getImmInfos", id);
  if (store.getters['intranet/immInfos']) {
    imm.value = store.getters['intranet/immInfos'].imm
  }
};

const openFolder = (folder) => {
  router.push({
    name: 'Dossier',
    params: { 
      id: route.params.id,
      folderId: folder
      }
  })  
}

const openImm = () => {
  router.push({
    name: 'Racine',
    params: { 
      id: route.params.id
      }
  })  
}

const openIntranet = () => {
  router.push({
    name: 'Liste Intranet'
  })  
}

const openImmeuble = () => {
  router.push({
    name: 'Immeuble',
    params: { 
      id: route.params.id
      }
  })  
}

/*const tri = (table) => {
  table.sort((a, b) => {
      return a.name.localeCompare(b.name); // Utilise localeCompare pour un tri alphabétique correct
  });
}*/

onMounted(() => {
  setNavPills();
  fetchDataImmeuble();
  fetchData();
});

watch(route, () => {
  fetchData()
})

const folderDataTable = computed(() => {
  return folderData.value
})

const fileDataTable = computed(() => {
  return fileData.value
})

const folderDataRightTable = computed(() => {
  return folderDataRight.value
})

const thisFolderRightTable = computed(() => {
  return thisFolderRight.value
})

const fileDataRightTable = computed(() => {
  return fileDataRight.value
})

const immInfos = computed (() => { 
  return imm.value
})

const chemin = computed (() => { 
  return cheminData.value
})

const usersTable = computed (() => { 
  return usersData.value
})

</script>
