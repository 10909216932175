<script setup>
import { useStore } from 'vuex'
import { ScheduleXCalendar } from '@schedule-x/vue'
import { createCurrentTimePlugin } from '@schedule-x/current-time'
import { createResizePlugin } from '@schedule-x/resize'
import { createEventModalPlugin } from '@schedule-x/event-modal'
import { createScrollControllerPlugin } from '@schedule-x/scroll-controller'
import { createEventsServicePlugin } from '@schedule-x/events-service'
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
} from '@schedule-x/calendar'
import '@schedule-x/theme-default/dist/index.css'
import {ref, onMounted } from 'vue'

// Do not use a ref here, as the calendar instance is not reactive, and doing so might cause issues
// For updating events, use the events service plugin

const store = useStore();

const scrollController = createScrollControllerPlugin({
  initialScroll: // current hour - 3 hours
  (new Date().getHours() - 3).toString().padStart(2, '0')+':00',
})

const eventsServicePlugin = createEventsServicePlugin();

function convertDate(dateStr) {
    // Create a new Date object using the input string
    const date = new Date(dateStr);

    // Format the date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Construct the new date string
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}


const updateEvents = async (range)=> {

    await  store.dispatch("calendar/eventsList", range);
      const val = store.getters["calendar/eventsList"];
        console.log('events', val)

        // get all events from the calendar and remove them
        eventsServicePlugin.getAll().forEach((event) => {
            eventsServicePlugin.remove(event.id)
        })

        val.events.forEach((event) => {
            if(event.start.dateTime == null || event.end.dateTime == null) return;
            // if event is not in the calendar, add it
            if (!eventsServicePlugin.get(event.id)) {
                eventsServicePlugin.add({
                    id: event.id,
                    title: event.subject,
                    start: convertDate(event.start.dateTime),
                    end: convertDate(event.end.dateTime),
                    description: event.bodyPreview,
                    people: [event.organizer.emailAddress.name],
                    calendarId: 'personal',
                })
            } else {
                // if event is in the calendar, update it
                eventsServicePlugin.update(event.id, {
                    title: event.subject,
                    start: convertDate(event.start.dateTime),
                    end: convertDate(event.end.dateTime),
                    description: event.bodyPreview,
                    people: [event.organizer.emailAddress.name],
                    calendarId: 'personal',
                })
            }

        })





      console.log('new calendar range start date', range.start)
      console.log('new calendar range end date', range.end)
}


const calendarApp = createCalendar({

  locale: 'fr-FR',
//  selectedDate: new Date().toJSON(),
  views: [
    createViewDay(),
    createViewWeek(),
    createViewMonthGrid(),
    createViewMonthAgenda()

  ],
  monthGrid: {
    weekStartsOn: 1,
    nEventsPerDay: 3
  },

  plugins: [createCurrentTimePlugin(),createResizePlugin(),createEventModalPlugin(),scrollController,eventsServicePlugin],
  callbacks:{
    async onRangeUpdate(range) {
        updateEvents(range)
    },
  },

  calendars: {
    personal: {
      colorName: 'personal',
      lightColors: {
        main: '#f9d71c',
        container: '#fff5aa',
        onContainer: '#594800',
      },
      darkColors: {
        main: '#fff5c0',
        onContainer: '#fff5de',
        container: '#a29742',
      },
    },
    mate: {
      colorName: 'work',
      lightColors: {
        main: '#f91c45',
        container: '#ffd2dc',
        onContainer: '#59000d',
      },
      darkColors: {
        main: '#ffc0cc',
        onContainer: '#ffdee6',
        container: '#a24258',
      },
    },
},
})

onMounted(() => {
  //updateEvents({})
  getCalendars()
  setTimeout(() => {
    updateEvents({
        start: new Date(new Date().setMonth(new Date().getMonth() - 1)).toJSON(),
        end: new Date(new Date().setMonth(new Date().getMonth() + 1)).toJSON(),
    })
  }, 500)
})

const calendars_list = ref([]);
const getCalendars = async () => {
    await store.dispatch("calendar/getCalendars");
    const val = store.getters["calendar/calendars"];
    console.log('calendars', val)
    // json decode
    calendars_list.value = val

    calendars_list.value.forEach((calendar) => {
      console.log('calendar', calendar)
    })
}

//
/*
const inferiorPeople = () => {
    let collabs = JSON.parse(localStorage.getItem('collabs'));
    if(collabs){
        return collabs
        .sort((a, b) => a.user.family_name.localeCompare(b.user.family_name))*/
        //.filter((collab) => collab.user.id != store)

        /*.map((collab) => {
            return (
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="me-2">
                            <div class="text-success">•</div>
                        </div>
                        <div class="d-flex flex-column">
                            <span class="h6 mb-0">{collab.user.name}</span>
                        </div>
                    </div>
                    <div class="form-check
                    form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                        />
                    </div>
                </div>
            )
        })*/
       /*
    }
}*/



</script>

<template>
  <div class="row">
    <div class="col-sm-3 " style="padding-right: 0px;">
        <div class="card m-2 mr-0">
            <div class="card-header">
            <h4 class="card-title">Calendrier</h4>
            </div>
            <div
            class="card-body"
            style="height: 80vh; overflow-y: auto;"
            >
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="me-2">
                        <div class="text-success">•</div>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="h6 mb-0">Personnel</span>
                    </div>
                </div>
                <div class="form-check
                form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center" v-for="people in calendars_list" :key="people.id">
                <div class="d-flex align-items-center">
                    <div class="me-2">
                        <div class="text-success">•</div>
                    </div>
                    <div class="d-flex flex-column">
                        <span class="h6 mb-0">{{people.name}}</span>
                    </div>
                </div>
                <div class="form-check
                form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                    />
                </div>
            </div>
        </div>
    </div>

    </div>
    <div class="col-sm-9 " style="padding-left: 0px;">
        <ScheduleXCalendar :calendar-app="calendarApp" style="height:90vh" class="card m-2 border-none" />

    </div>
  </div>
</template>
<style>
:root{
    --sx-color-primary: #fd7e14;

}
.sx__calendar {
    border: none !important;
}
</style>
