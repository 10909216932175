<template>
  <div class="py-1 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="bg-gradient-danger p-1 border-radius-xl d-flex">
          <h6 class="mb-0 text-white px-2">Liste des immeubles</h6>
          <div style="flex:1"></div>
          <button class="btn btn-xs btn-round btn-white mb-0" @click="router.push({name:'Carte des immeubles'})">
            <i class="fa fa-map me-1"></i> Carte
          </button>
        </div>

        <div class="container text-center mb-2">
          <div class="mt-2">
            <ArgonInput icon="fa fa-search" formgroup_classes="mb-1" iconDir="left" v-model="search" placeholder="Rechercher"/>
            <div class="row">
                <div class="col-sm-4">
              <el-select
                style="color: red;"
                v-model="sort"
                class="select-primary pagination-select col-sm-4"
                placeholder="Tri"
                name="Tri"
                size="small"
              >
                <el-option
                  key="EXTAPI_IMM_ID"
                  class="select-primary"
                  label="Trier par référence croissante"
                  value="EXTAPI_IMM_ID"
                />
                <el-option
                  key="nom"
                  class="select-primary"
                  label="Trier par nom croissant"
                  value="nom"
                />
                <el-option
                  key="events.last"
                  class="select-primary"
                  label="Trier par dernier évènement"
                  value="events.last"
                />
              </el-select></div>
              <div class="col-sm-3">
              <el-select
                v-model="onlyme"
                class="select-primary pagination-select col-sm-3"
                placeholder="Affichage"
                size="small"
                filterable
              >
                <el-option
                  class="select-primary"
                  key="Seulement mes immeubles"
                  label="Seulement mes immeubles"
                  value="Seulement mes immeubles"
                />
                <el-option
                  class="select-primary"
                  key="Tous les immeubles actifs du parc"
                  label="Tous les immeubles actifs du parc"
                  value="Tous les immeubles actifs du parc"
                />
                <el-option
                  class="select-primary"
                  key="Tous les immeubles du parc"
                  label="Tous les immeubles du parc"
                  value="Tous les immeubles du parc"
                />
                <el-option
                  v-for="coll in collabs"
                  class="select-primary"
                  :key="coll.id"
                  :label="coll.name"
                  :value="coll.id"
                />
              </el-select></div>

              <div class="col-sm-2">
              <el-select
                style="color: red;"
                class="select-primary pagination-select col-sm-2"
                v-model="city"
                placeholder="Code Postal"
                name="Code Postal"
                size="small"
                filterable
                allow-create
                no-data-text="Merci d'entrer un code postal"
              >
                <el-option
                  class="select-primary"
                  key="Tous les codes postaux"
                  label="Tous les codes postaux"
                  value="Tous les codes postaux"
                />
              </el-select></div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="px-0 pb-0 pt-1 card-body">
            <div class="table-responsive" id="infinite-list" style="height:calc(100vh - 200px)" @scroll="handleScroll">
              <table class="table table-responsive">
                <tbody class="text-sm">
                  <tr v-for="immeuble in roleList" :key="immeuble.id" @click="openImmeuble(immeuble)">
                    <td class="text-danger" style="cursor: pointer;">{{ immeuble.EXTAPI_IMM_ID }}</td>
                    <td style="flex:2;width: 100%;cursor: pointer;">{{ immeuble.nom }}</td>
                    <td style="flex:1;width: 100%;cursor: pointer;">{{ immeuble.adresse1 }} {{ immeuble.adresse3 }}</td>
                    <td style="flex:1;width: 100%;cursor: pointer;">{{ immeuble.ville }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from 'lodash'
import ArgonInput from '../../../components/ArgonInput.vue'
import { ElSelect, ElOption } from 'element-plus'

const store = useStore()
const router = useRouter()

const sort = ref('EXTAPI_IMM_ID')
const city = ref(null)
const onlyme = ref('Seulement mes immeubles')
const updatedList = ref([])
const page_t = ref(1)
const search = ref('')
const collabs = ref([])

const roleList = computed(() => getList())
const metaPage = computed(() => store.getters['immeuble/immeublesList']?.meta)

const getRoleList = _.debounce(async (params) => {
  await store.dispatch('immeuble/immeublesList', {
    ...(params.sort ? { sort: params.sort } : {}),
    ...(params.city ? { city: params.city } : {}),
    ...(params.query ? { search: params.query } : {}),
    ...(params.onlyme === 'Seulement mes immeubles' ? { only: 'me' } : {}),
    ...(params.onlyme === 'Tous les immeubles du parc' ? { withlost: '1' } : {}),
    ...(params.onlyme !== 'Seulement mes immeubles' && params.onlyme !== 'Tous les immeubles du parc' && params.onlyme !== 'Tous les immeubles actifs du parc' ? { other_collab: params.onlyme } : {}),
    page: params.nr,
    size: 25,
  })
}, 300)

const getList = () => {
  if (store.getters['immeuble/immeublesList']) {
    if (store.getters['immeuble/immeublesList'].meta.current_page === 1) {
      updatedList.value = store.getters['immeuble/immeublesList'].data
    } else {
      updatedList.value = [...updatedList.value, ...store.getters['immeuble/immeublesList'].data]
    }
  }
  return updatedList.value
}

const openImmeuble = (row) => {
  router.push({
    name: 'Racine',
    params: { id: row.id }
  })
}

const getDataFromPage = async (page) => {
  await getRoleList({
    sort: sort.value,
    city: city.value,
    onlyme: onlyme.value,
    query: search.value,
    nr: page,
    perpage: 15
  })
}

const reactiveSearch = async () => {
  await getRoleList({
    sort: sort.value,
    city: city.value,
    onlyme: onlyme.value,
    query: search.value,
    nr: 1,
    perpage: 15
  })
}

const handleScroll = (e) => {
  const masonry = e.target
  if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
    if (metaPage.value.current_page === 1) {
      page_t.value = 1
    }
    if (page_t.value <= metaPage.value.current_page && page_t.value <= metaPage.value.last_page) {
      page_t.value += 1
      getDataFromPage(page_t.value)
    }
  }
}

onMounted(async () => {
  const liste = JSON.parse(localStorage.getItem('collabs') || '[]')
  collabs.value = liste.map(element => ({
    name: `${element.user.given_name} ${element.user.family_name}`,
    id: element.id
  }))

  await getRoleList({
    sort: sort.value,
    city: city.value,
    onlyme: onlyme.value,
    query: '',
    nr: 1,
    perpage: 15
  })
})

watch([sort, city, onlyme, search], reactiveSearch)
</script>

<style>
.dataTable-top {
  display: none;
}
.el-radio-button {
  color: red;
  padding: 20em;
}
.el-radio-button .el-radio-button__inner {
  color: blue;
}
.el-radio-button .el-radio-button__inner:hover {
  color: red;
}
.card .table td:hover,
.card .table th:hover,
.card .el-table td:hover,
.card .el-table th:hover {
  background-color: rgba(255, 83, 83, 0) !important;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
</style>
